import React, { useState } from 'react'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Masonry from 'react-masonry-css'
import { Faq } from "../../subitems/faq/house"
import { CalculatorLink } from "../../components/calculator/calculatorLink"
import { SchemaCost } from "../../subitems/schema-cost"
import { ServicesProcess } from "../../subitems/services/process"
import { ServicesTermHouse } from "../../subitems/services/term/house"

import { Video } from "../../subitems/hompage/video"
import mainVideo from "../../video/design_interiera_house.mp4"
import mainVideoMobile from "../../video/design_interiera_house_mob.mp4"
import { ControlVideo } from "../../subitems/services/control-video"


const DesignInterieraDomov = () => {
  const [openTop , setOpenTop] = useState(false);
  const [openBottom , setOpenBottom] = useState(false);
  const toggleTop =()=>{
    setOpenTop(!openTop)
  }
  const toggleBottom =()=>{
    setOpenBottom(!openBottom)
  }
  return (
    <Layout>
      <Seo  title={"Дизайн проект частного дома в Минске | I-PROJECT"} description={"Проектируем и реконструируем частные дома, загородные дома и коттеджи любой сложности. Реализованные объекты ✦ Реконструкция фасада ✦ Опыт более 15 лет."}
            location={"https://i-project.by/uslugi/dizajn-interera-domov"}
            image={"https://i-project.by/portfolio_newsite/korolya/01_korolya.webp"}/>
      <div class="services_video">
        <Video  mobileSrc={mainVideoMobile} desktopSrc={mainVideo} />
        <div class="wrapper">
          <div class="services_video_text">
            <h1 class="services_video_title">Дизайн проект частного дома</h1>
            <p class="services_video_p">Воплотим ваши мечты и&nbsp;идеи в&nbsp;жизнь благодаря слаженной работе грамотных специалистов&nbsp;&mdash; архитекторов, дизайнеров и&nbsp;строителей.</p>
          </div>
        </div>
      </div>
      <div class="content_body">
        <div class="content_body_absolute">
          <div class="left_sidebar">
            <ul>
              <li><AnchorLink to="/uslugi/dizajn-interera-domov/#anchor_1" title="Как строится работа" /></li>
              <li><AnchorLink to="/uslugi/dizajn-interera-domov/#anchor_2" title="Сроки и стоимость" /></li>
              <li><AnchorLink to="/uslugi/dizajn-interera-domov/#anchor_3" title="Портфолио" /></li>
              <li><AnchorLink to="/uslugi/dizajn-interera-domov/#anchor_4" title="Что входит в&nbsp;проект" /></li>
              <li><AnchorLink to="/uslugi/dizajn-interera-domov/#anchor_5" title="Контроль за подрядчиками" /></li>
              <li><AnchorLink to="/uslugi/dizajn-interera-domov/#anchor_6" title="Вопрос / ответ" /></li>
            </ul>
          </div>
        </div>
        <div class="wrapper">
          <div class="right_content">
            <section class="services_process" id="anchor_1">
              <ServicesProcess />
            </section>
            <div class="services_process_link_block">
              <p class="services_process_link_block_p">Как мы работаем</p>
              <Link to="/process" className="services_process_link">Подробнее о процессе</Link>
            </div>
            <section id="anchor_2">
              <ServicesTermHouse />
            </section>
            <section>
              <h2 class="main_portfolio_title" id="anchor_3">Наши работы</h2>
              <Masonry
                breakpointCols={breakpointColumnsPortfolio}
                className="portfolio_grid"
                columnClassName="my-masonry-grid_column">
                <Link to="/portfolio/interier/garmony-dom-interier" className="portfolio_grid_link">
                  <StaticImage src="../../images/portfolio/garmony_dom_interier/00_garmony_dom.jpg" alt="" />
                  <div class="portfolio_grid_block">
                    <h3 className="portfolio_grid_block_title">Гармоничный дом. Интерьер, 400м<sup>2</sup></h3>
                    <p className="portfolio_grid_block_realize">Реализовано</p>
                  </div>
                </Link>
                <Link to="/portfolio/interier/interier-baltic-house" className="portfolio_grid_link">
                  <StaticImage src="../../images/portfolio/baltic_house/interior/00_baltic_home_interior.jpg" alt="" />
                  <div class="portfolio_grid_block">
                    <h3 className="portfolio_grid_block_title">Дом с&nbsp;балтийским характером. Интерьер, 500м<sup>2</sup></h3>
                    <p className="portfolio_grid_block_realize">Реализовано</p>
                  </div>
                </Link>
                <Link to="/portfolio/interier/tarasovo-interier" className="portfolio_grid_link">
                  <StaticImage src="../../images/portfolio/tarasovo_interier/00_tarasovo.jpg" alt="" />
                  <div class="portfolio_grid_block">
                    <h3 className="portfolio_grid_block_title">Дом в&nbsp;Тарасово. Интерьер, 800м<sup>2</sup></h3>
                  </div>
                </Link>
                <Link to="/portfolio/architecture/dom-u-more" className="portfolio_grid_link">
                  <StaticImage src="../../images/portfolio/dom_u_more/00_dom_u_more.jpg" alt="" />
                  <div class="portfolio_grid_block">
                    <h3 className="portfolio_grid_block_title">Дом у&nbsp;моря. Архитектурное решение + Интерьер, 1000м<sup>2</sup></h3>
                  </div>
                </Link>
                <Link to="/portfolio/interier/zelenyi" className="portfolio_grid_link portfolio_grid_link_hide">
                  <StaticImage src="../../images/portfolio/zelenyi/00_zelenyi.jpg" alt="" />
                  <div class="portfolio_grid_block">
                    <h3 className="portfolio_grid_block_title">50 оттенков зеленого. Интерьер дома, 250м<sup>2</sup></h3>
                  </div>
                </Link>
                <Link to="/portfolio/interier/simple-house" className="portfolio_grid_link portfolio_grid_link_hide">
                  <StaticImage src="../../images/portfolio/simple_house/00_simple_house.jpg" alt="" />
                  <div className="portfolio_grid_block">
                    <h3 className="portfolio_grid_block_title">Дизайн интерьера обычного дома, 250м<sup>2</sup></h3>
                  </div>
                </Link>
                <></>
                <Link to="/portfolio" className="portfolio_grid_link_all">
                  <p className="portfolio_grid_link_all_p">Все проекты</p>
                </Link>
              </Masonry>
            </section>
            <div class="services_include" id="anchor_4">
              <h2 class="services_include_title">Что входит в&nbsp;проект:</h2>
              <div class="services_include_item">
                <h3 class="services_include_item_smtitle">Документация</h3>
                <div class="services_include_text">
                  <ul class={"services_include_item_list  " + ( openTop?" open" : "")}>
                    <li>1. Планировочная концепция (несколько вариантов)</li>
                    <li>2. Обмерочные чертежи (планы)</li>
                    <li>3. Планы с&nbsp;обозначением демонтируемых элементов стен, перегородок</li>
                    <li>4. Планы с&nbsp;обозначением возводимых элементов стен, перегородок</li>
                    <li>5. Планы с&nbsp;расстановкой мебели и&nbsp;привязками сантехнического оборудования</li>
                    <div class={"services_include_list_hidden" + ( openTop?" open" : "")}>
                      <li>6. Планы с&nbsp;обозначением напольных покрытий</li>
                      <li>7. Схема подогрева полов</li>
                      <li>8. Планы подвесных потолков</li>
                      <li>9. Схема установки кондиционеров</li>
                      <li>10. Сечения подвесных потолков</li>
                      <li>11. Планы установки светильников и&nbsp;выключателей</li>
                      <li>12. Спецификация светильников и&nbsp;выключателей</li>
                      <li>13. Планы установки розеток</li>
                      <li>14. Спецификация розеток</li>
                      <li>15. Ведомость внутренней отделки помещений</li>
                      <li>16. Разрезы и(или) развертки по&nbsp;всем стенам с&nbsp;нанесением основных размеров</li>
                      <li>17. Разрезы и(или) развертки по&nbsp;всем стенам в&nbsp;цвете</li>
                      <li>18. Узлы, фрагменты (укрупненные с&nbsp;размерами)</li>
                      <p class="services_include_item_list_p">Общий объем: 30–50 листов А3, А4</p>
                    </div>
                    <button onClick={toggleTop}  class="services_include_button">{openTop? 'Свернуть все' : 'Показать все'}</button>
                  </ul>
                  <button onClick={toggleTop} class={"services_include_mob_button" + ( openTop?" open" : "")}>
                    <p>Список документов</p>
                  </button>
                  <div class="services_include_img_mob_block">
                    <h3 class="services_include_item_smtitle_mob">Документация</h3>
                    <StaticImage class="services_include_img_mob" src="../../images/services/services_include_house_1.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="services_include_item">
                <h3 class="services_include_item_smtitle">Фотореалистичная 3D-визуализация</h3>
                <div class="services_include_text">
                  <ul class={"services_include_item_list " + (openBottom ? " open": "")}>
                    <p class="services_include_item_list_sp">Прежде, чем приступить к&nbsp;этому этапу, просматриваем вместе примеры удачных интерьеров и(или) архитектурных объектов, разработанных как внутри нашей студии, так и&nbsp;в&nbsp;мире в&nbsp;целом. Собираем кейс понравившихся работ&nbsp;&mdash; отмечаем, что именно в&nbsp;них Вас привлекает или отталкивает. Определяемся со&nbsp;стилистическими и&nbsp;цветовыми предпочтениями.</p>
                    <li>&mdash;&nbsp;Обычно 3-5 ракурсов на&nbsp;одно помещение</li>
                    <li>&mdash;&nbsp;Совмещенная зона кухни-столовой-гостиной: 7-10 ракурсов</li>
                    <li>&mdash;&nbsp;Количество помещений, для которых требуется визуализация, уточняется индивидуально для каждого проекта, но, как минимум, необходимо визуализировать основные помещения: прихожая, гостиная, кухня, столовая, жилые комнаты (спальни хозяйские и&nbsp;детские).</li>
                  </ul>
                  <button onClick={toggleBottom} class={"services_include_mob_button" + ( openBottom?" open" : "")}>
                    <p>Список документов</p>
                  </button>
                  <div class="services_include_img_mob_block">
                    <h3 class="services_include_item_smtitle_mob">Фотореалистичная<br />3D-визуализация</h3>
                    <StaticImage class="services_include_img_mob" src="../../images/services/services_include_house_2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>     
        </div>
      </div>
      <div class="wrapper" id="anchor_5">
        <div class="services_include_item services_include_item_control services_include_item_control_new">
          <div class="services_include_item_control_left">
            <h3 class="services_include_item_smtitle">Контроль за&nbsp;подрядчиками</h3>
            <ul class="services_include_item_list services_include_item_list_new">
              <li>Схемы и&nbsp;расчет системы отопления, спецификация оборудования</li>
              <li>Схемы и&nbsp;расчет системы вентиляции и&nbsp;кондиционирования помещений, спецификация оборудования</li>
              <li>Схемы и&nbsp;расчет системы управления электроосвещением и&nbsp;электрооборудованием &laquo;Умный Дом&raquo;, спецификация оборудования</li>
              <li>Схемы раскладки плитки, спецификация элементов</li>
            </ul>
          </div>
          <div class="services_include_item_control_right">
            <ControlVideo />
          </div>
        </div>
        <section>
          <SchemaCost />
          <CalculatorLink />
        </section>
        <section>
          <div className="faq" id="anchor_6">
            <h3 className="faq_title">Вопрос / ответ</h3>
            <Faq/>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default DesignInterieraDomov

const breakpointColumnsPortfolio = {
  default: 3,
  1800: 2,
  768: 1,
  
};